import service from '../utils/request'

// 任务最新数据
export function findTaskNewData(type, taskId) {
  return service({
    method: 'get',
    url: `/view/find${type.indexOf('V3') != -1 ? type[0] : type}New`,
    params: {
      taskId,
      version: type.indexOf('V3') != -1 ? 'V3' : 'V2',
    },
  })
}

// 测点数据查询
export function findPointData(protocol, type, taskId, itemId, page, pageSize, time1, time2) {
  return service({
    method: 'get',
    url: `data/find${protocol.indexOf('V3') != -1 ? protocol[0] : protocol}${type}`,
    params: {
      taskId,
      itemId,
      page,
      pageSize,
      time1,
      time2,
      version: protocol.indexOf('V3') != -1 ? 'V3' : 'V2',
    },
  })
}

// 测点数据同组查询
export function findPointDataByGroup(protocol, type, taskId, page, pageSize, time1, time2) {
  return service({
    method: 'get',
    url: `data/find${protocol.indexOf('V3') != -1 ? protocol[0] : protocol}${type}`,
    params: {
      taskId,
      page,
      pageSize,
      time1,
      time2,
      version: protocol.indexOf('V3') != -1 ? 'V3' : 'V2',
    },
  })
}

// 测点历史数据查询
export function findPointHistoryData(protocol, type, taskId, itemId, page, pageSize, startTime, endTime) {
  return service({
    method: 'get',
    url: `historical/find${protocol.indexOf('V3') != -1 ? protocol[0] : protocol}${type}Historical`,
    params: {
      taskId,
      itemId,
      page,
      pageSize,
      startTime,
      endTime,
      version: protocol.indexOf('V3') != -1 ? 'V3' : 'V2',
    },
  })
}
// 测点历史数据同组查询
export function findPointHistoryDataByGroup(protocol, type, taskId, page, pageSize, startTime, endTime) {
  return service({
    method: 'get',
    url: `historical/find${protocol.indexOf('V3') != -1 ? protocol[0] : protocol}${type}Historical`,
    params: {
      taskId,
      page,
      pageSize,
      startTime,
      endTime,
      version: protocol.indexOf('V3') != -1 ? 'V3' : 'V2',
    },
  })
}

// .....

export function findResultByIds(type, ids, startTime, endTime) {
  return service({
    method: 'get',
    url: '/data/findResultByIds',
    params: {
      ids,
      startTime,
      endTime,
      version: type.indexOf('V3') != -1 ? 'V3' : 'V2',
    },
  })
}
