<template>
  <div>
    <MyTable
      ref="pointList"
      :dataSource="dataSource"
      :maxHeight="maxHeight"
      :columns="columns"
      :selection="selection"
      border
      stripe
      :loading="loading"
      :total="total"
      :currentPage="currentPage"
      :pageSize="pageSize"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      @handleSelectionChange="handleSelectionChange"
      @clearSelect="clearSelect"
    ></MyTable>
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'

const HighMoldAxialFItem = [
  {
    prop: 'pointName',
    label: '测点编号',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'createTime',
    label: '测量日期',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'dataBatch',
    label: '数据批次',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'measurements',
    label: '测量值(kg)',
    type: 'number',
    decimal: 1,
    align: 'center',
  },
  {
    prop: 'axialForce',
    label: '轴力(N)',
    type: 'number',
    decimal: 1,
    align: 'center',
  },
  {
    prop: 'axialForceChange',
    label: '累计变化(N)',
    describe: '受压为正值,受拉为负值',
    type: 'number',
    decimal: 1,
    align: 'center',
  },
]
const HighMoldInclinationLItem = [
  {
    prop: 'pointName',
    label: '测点编号',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'createTime',
    label: '测量日期',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'dataBatch',
    label: '数据批次',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'xAngle',
    label: 'X角度(°)',
    decimal: 3,
    type: 'number',
    align: 'center',
  },
  {
    prop: 'xAngleChange',
    label: 'X角度累计变化(°)',
    decimal: 1,
    type: 'number',
    align: 'center',
  },
  {
    prop: 'xAngleAccumulateChange',
    label: 'X倾角(‰)',
    describe: '正向倾斜为正值,反向倾斜为负值',
    decimal: 3,
    type: 'number',
    align: 'center',
  },
  {
    prop: 'yAngle',
    label: 'Y角度(°)',
    decimal: 3,
    type: 'number',
    align: 'center',
  },
  {
    prop: 'yAngleChange',
    label: 'Y角度累计变化(°)',
    decimal: 1,
    type: 'number',
    align: 'center',
  },
  {
    prop: 'yAngleAccumulateChange',
    label: 'Y倾角(‰)',
    describe: '正向倾斜为正值,反向倾斜为负值',
    decimal: 3,
    type: 'number',
    align: 'center',
  },
]
const HighMoldLaserHItem = [
  {
    prop: 'pointName',
    label: '测点编号',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'createTime',
    label: '测量日期',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'dataBatch',
    label: '数据批次',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'xDisplacementAccumulateChange',
    label: 'X方向累计位移(mm)',
    decimal: 1,
    describe: '正向位移为正值,反向位移为负值',
    type: 'number',
    align: 'center',
  },
  {
    prop: 'yDisplacementAccumulateChange',
    label: 'Y方向累计位移(mm)',
    describe: '正向位移为正值,反向位移为负值',
    decimal: 1,
    type: 'number',
    align: 'center',
  },
]
const HighMoldLaserVItem = [
  {
    prop: 'pointName',
    label: '测点编号',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'createTime',
    label: '测量日期',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'dataBatch',
    label: '数据批次',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'displacement',
    // prop: 'measurements',
    describe: '红色字体为异常数据,不是报警数据',
    label: '测距值(mm)',
    decimal: 1,
    type: 'abnormal_number',
    align: 'center',
  },
  {
    prop: 'displacementChange',
    // prop: 'measurementsChange',
    label: '累计位移(mm)',
    describe: '沉降为负值,抬升为正值',
    isCustom: true,
    decimal: 1,
    type: 'number',
    align: 'center',
  },
]

export default {
  components: { MyTable },
  data() {
    return {
      columns: [],
    }
  },
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
    protocol: {
      type: String,
    },
    total: {
      type: Number,
    },
    currentPage: {
      type: Number,
    },
    pageSize: {
      type: Number,
    },
    maxHeight: {
      type: Number,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    selection: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    protocol() {
      this.protocolMatch()
    },
  },
  methods: {
    // 根据协议 匹配表格
    protocolMatch() {
      switch (this.protocol) {
        case 'HighMoldAxialFItem':
          this.columns = HighMoldAxialFItem
          break
        case 'HighMoldInclinationLItem':
          this.columns = HighMoldInclinationLItem
          break
        case 'HighMoldLaserHItem':
          this.columns = HighMoldLaserHItem
          break
        case 'HighMoldLaserVItem':
          this.columns = HighMoldLaserVItem
          break
        case 'HighMoldAxialFItemV3':
          this.columns = HighMoldAxialFItem
          break
        case 'HighMoldInclinationLItemV3':
          this.columns = HighMoldInclinationLItem
          break
        case 'HighMoldLaserHItemV3':
          this.columns = HighMoldLaserHItem
          break
        case 'HighMoldLaserVItemV3':
          this.columns = HighMoldLaserVItem
          break
      }
    },
    // 修改 表格条数
    handleSizeChange(val) {
      console.log('每页' + val + '条')
      this.$emit('handleSizeChange', val)
    },
    // 修改 表格页数
    handleCurrentChange(val) {
      console.log('第' + val + '页')
      this.$emit('handleCurrentChange', val)
    },
    handleSelectionChange(e) {
      this.$emit('handleSelectionChange', e)
    },
    clearSelect() {
      console.log(123)
      this.$emit('clearSelect')
    },
  },
  created() {
    this.protocolMatch()
  },
  mounted() {},
}
</script>

<style scoped lang="scss"></style>
